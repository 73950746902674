import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'
import SEO from '../../components/seo'
import NewsSection from '../../components/NewsSection'
import * as s from '../../pages/news.module.scss'
import Button from '../../components/button/button'
import { toLink } from '../../utils'
import Link from '../../components/Common/Link'

const OnlineConfSignalTools = () => {
  return (
    <>
      <SEO
        title="Онлайн конференция SIGNAL"
        description="Пользователи делятся опытом в работе с SIGNAL TOOLS и цифровизации своих процессов"
      />
      <NewsSection
        title={`Запись онлайн-конференции SIGNAL\nОпыт ведущих компаний в использовании TOOLS`}
        dateData={{ date: '01.10.2023' }}
        rayContent
      >
        <p className={s.text}>
          Пропустили нашу онлайн конференцию, на которой ведущие проектные и
          строительные компании поделятся своим реальным опытом работы и
          покажут, для решения каких задач они применяют SIGNAL TOOLS?
        </p>
        <p className={s.text}>
          Мы подготовили для вас запись, которую можно посмотреть на нашем
          youtube-канале
        </p>
        <div className="w-100 flex justify-center flex-col items-center">
          <Button
            className="my-5"
            onClick={() =>
              toLink('https://www.youtube.com/watch?v=Eznivw1F2AE')
            }
          >
            Смотреть запись
          </Button>
        </div>
        <p className={s.text}>Тайминг:</p>
        <p className={s.text}>
          <Link
            href="https://www.youtube.com/watch?v=Eznivw1F2AE&t=124s"
            rel="nofollow noreferrer"
            target="_blank"
          >
            02:00
          </Link>{' '}
          Вступительное слово вендора. Сергей Казанцев, SIGNAL
        </p>
        <p className={s.text}>
          <Link
            href="https://www.youtube.com/watch?v=Eznivw1F2AE&t=470s"
            rel="nofollow noreferrer"
            target="_blank"
          >
            07:50
          </Link>{' '}
          Использование TOOLS для подтверждения объемов в модели, Алексей
          Новиков, ООО ПИК “АРЗИС”
        </p>
        <p className={s.text}>
          <Link
            href="https://www.youtube.com/watch?v=Eznivw1F2AE&t=1582s"
            rel="nofollow noreferrer"
            target="_blank"
          >
            26:22
          </Link>{' '}
          Управление строительством объектов метрополитена с применением SIGNAL
          TOOLS, Александр Полянкин, МОСИНЖПРОЕКТ
        </p>
        <p className={s.text}>
          <Link
            href="https://www.youtube.com/watch?v=Eznivw1F2AE&t=3199s"
            rel="nofollow noreferrer"
            target="_blank"
          >
            53:19
          </Link>{' '}
          Опыт использования Signal tools для анализа проектов жилой
          недвижимости, Иван Коняев, Level Group
        </p>
        <p className={s.text}>
          <Link
            href="https://www.youtube.com/watch?v=Eznivw1F2AE&t=4241s"
            rel="nofollow noreferrer"
            target="_blank"
          >
            1:10:41
          </Link>{' '}
          Ведение накопительной модели с использованием плагина SIGNAL TOOLS,
          Евгений Чаплыгин, ГК Пионер
        </p>
        <p className={s.text}>Спикеры:</p>
        <div className="flex w-100 flex-wrap">
          <div style={{ width: '232px' }}>
            <StaticImage
              width={150}
              imgStyle={{ mixBlendMode: 'multiply' }}
              src="../../assets/images/news/online-conf-signal-tools/kazantsev.jpeg"
              alt="Сергей Казанцев"
              title="Сергей Казанцев"
              placeholder="none"
            />
            <p className={cn(s.text, 'm-0')}>Сергей Казанцев</p>
            <p className={cn(s.text, 'm-0')}>
              {`Директор по цифровому\nстроительству SIGNAL`}
            </p>
          </div>
          <div style={{ width: '232px' }}>
            <StaticImage
              width={150}
              imgStyle={{ mixBlendMode: 'multiply' }}
              src="../../assets/images/news/online-conf-signal-tools/novikov.jpeg"
              alt="Алексей Новиков"
              title="Алексей Новиков"
              placeholder="none"
            />
            <p className={cn(s.text, 'm-0')}>Алексей Новиков</p>
            <p className={cn(s.text, 'm-0')}>
              {`BIM-менеджер\nООО ПИК “АРЗИС”`}
            </p>
          </div>
          <div style={{ width: '232px' }}>
            <StaticImage
              width={150}
              imgStyle={{ mixBlendMode: 'multiply' }}
              src="../../assets/images/news/online-conf-signal-tools/konyaev.jpeg"
              alt="Иван Коняев"
              title="Иван Коняев"
              placeholder="none"
            />
            <p className={cn(s.text, 'm-0')}>Иван Коняев</p>
            <p className={cn(s.text, 'm-0')}>
              {`BIM-Координатор\nLevel Group`}
            </p>
          </div>
          <div style={{ width: '232px' }}>
            <StaticImage
              width={150}
              imgStyle={{ mixBlendMode: 'multiply' }}
              src="../../assets/images/news/online-conf-signal-tools/polyankin.jpeg"
              alt="Александр Полянкин"
              title="Александр Полянкин"
              placeholder="none"
            />
            <p className={cn(s.text, 'm-0')}>Александр Полянкин</p>
            <p className={cn(s.text, 'm-0')}>
              {`Начальник отдела цифровизации МОСИНЖПРОЕКТ`}
            </p>
          </div>
          <div style={{ width: '232px' }}>
            <StaticImage
              width={150}
              imgStyle={{ mixBlendMode: 'multiply' }}
              src="../../assets/images/news/online-conf-signal-tools/chaplygin.jpeg"
              alt="Евгений Чаплыгин"
              title="Евгений Чаплыгин"
              placeholder="none"
            />
            <p className={cn(s.text, 'm-0')}>Евгений Чаплыгин</p>
            <p className={cn(s.text, 'm-0')}>{`BIM-Координатор\nГК Пионер`}</p>
          </div>
        </div>
      </NewsSection>
    </>
  )
}

export default OnlineConfSignalTools
